import RootErrorBoundary from "@components/RootErrorBoundary/index.ts";
import { SessionScaffold } from "@components/Scaffold/index.ts";
import DashboardPage from "@pages/DashboardPage/index.ts";
import LoginPage from "@pages/LoginPage/index.ts";
import LogoutPage from "@pages/LogoutPage/index.ts";
import RecoveryPage from "@pages/RecoveryPage/index.ts";
import RegistrationPage from "@pages/RegistrationPage/index.ts";
import AuthenticatorAppPage from "@pages/Settings/AuthenticatorAppPage/index.ts";
import BackupCodesPage from "@pages/Settings/BackupCodesPage/index.ts";
import SecurityKeysPage from "@pages/Settings/SecurityKeysPage/index.ts";
import SettingsPage from "@pages/Settings/SettingsPage/index.ts";
import UpdateEmailAddressPage from "@pages/Settings/UpdateEmailAddressPage/index.ts";
import UpdateNicknamePage from "@pages/Settings/UpdateNicknamePage/index.ts";
import UpdatePasswordPage from "@pages/Settings/UpdatePasswordPage/index.ts";
import VerificationPage from "@pages/VerificationPage/index.ts";
import { Navigate, Outlet, type RouteObject, createBrowserRouter } from "react-router-dom";

const pathRoutes: RouteObject[] = [
    {
        index: true,
        element: (
            <SessionScaffold>
                <DashboardPage />
            </SessionScaffold>
        ),
    },
    {
        path: "registration",
        element: <RegistrationPage />,
    },
    {
        path: "login",
        element: <LoginPage />,
    },
    {
        path: "verification",
        element: <VerificationPage />,
    },
    {
        path: "recovery",
        element: <RecoveryPage />,
    },
    {
        path: "logout",
        element: (
            <SessionScaffold>
                <LogoutPage />
            </SessionScaffold>
        ),
    },
    {
        path: "settings",
        element: <SessionScaffold />,
        children: [
            {
                index: true,
                element: <SettingsPage />,
            },
            {
                path: "nickname",
                element: <UpdateNicknamePage />,
            },
            {
                path: "email-address",
                element: <UpdateEmailAddressPage />,
            },
            {
                path: "password",
                element: <UpdatePasswordPage />,
            },
            {
                path: "security-keys",
                element: <SecurityKeysPage />,
            },
            {
                path: "authenticator",
                element: <AuthenticatorAppPage />,
            },
            {
                path: "backup",
                element: <BackupCodesPage />,
            },
        ],
    },
    {
        path: "*",
        element: <Navigate to="/" />,
    },
];

const rootRoute: RouteObject = {
    path: "/",
    element: <Outlet />,
    errorElement: <RootErrorBoundary />,
    children: pathRoutes,
};

export const router = createBrowserRouter([rootRoute]);

if (import.meta.hot) {
    import.meta.hot.dispose(() => router.dispose());
}

/*
export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Layout />}>
            <Route errorElement={<Error performReset />}>

                <Route path="error" element={<Error />} />
            </Route>
        </Route>,
    ),
);
*/
