import type { SxProps } from "@mui/material";
import { Avatar } from "@mui/material";
import type { Identity } from "@queries/session.ts";
import type { ReactNode } from "react";

type Props = {
    identity: Identity;
    size?: number;
    sx?: SxProps;
};

const UserAvatar = ({ identity, size, sx }: Props): ReactNode => {
    size ??= 40;

    const localSx = {
        ...sx,
        width: size,
        height: size,
        fontSize: `calc((${size} / 40) * 1.25rem)`,
    };

    if (!identity.picture) {
        return <Avatar sx={localSx}>{identity.nickname[0].toUpperCase()}</Avatar>;
    }

    let url: string;

    if (!size || size <= 64) {
        url = identity.picture.small;
    } else if (size <= 128) {
        url = identity.picture.normal;
    } else {
        url = identity.picture.original;
    }

    return <Avatar src={url} sx={localSx} alt={identity.nickname} />;
};

export default UserAvatar;
