import ErrorAlert from "@components/ErrorAlert";
import { useSession } from "@components/Scaffold/index.js";
import { zodResolver } from "@hookform/resolvers/zod";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import { Button, Container, Divider, IconButton, Paper, Stack, Typography } from "@mui/material";
import { useUpdateNicknameMutation } from "@mutations/profile.js";
import { RhfTextField } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { z } from "zod";

const schema = z.object({
    nickname: z.string().trim().min(2),
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

const UpdateNicknamePage = (): ReactNode => {
    const session = useSession();
    const updateNicknameMutation = useUpdateNicknameMutation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
        defaultValues: {
            nickname: session.identity.nickname,
        },
    });

    const handleSubmit = (values: TransformedValues) => {
        updateNicknameMutation.mutate(values, {
            onSuccess: () => {
                enqueueSnackbar("Your nickname has been updated", { variant: "success" });
                navigate("/settings");
            },
        });
    };

    return (
        <Container maxWidth="sm">
            <Stack direction="row" spacing={2} alignItems="center">
                <IconButton component={RouterLink} to="/settings">
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h5">Nickname</Typography>
            </Stack>

            <Divider sx={{ mt: 1, mb: 3 }} />

            <Paper variant="outlined" sx={{ p: { xs: 2, sm: 4 } }}>
                <Typography variant="body2" sx={{ mb: 4 }}>
                    Your nickname is visible on the website and will be printed on your badge.
                </Typography>

                <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
                    {updateNicknameMutation.isError && (
                        <ErrorAlert error={updateNicknameMutation.error} sx={{ mb: 4 }} />
                    )}

                    <RhfTextField
                        control={form.control}
                        label="Nickname"
                        name="nickname"
                        required
                        disabled={updateNicknameMutation.isPending}
                        fullWidth
                    />

                    <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 4 }}>
                        <Button component={RouterLink} to="/settings">
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            sx={{ mt: 4 }}
                            loading={updateNicknameMutation.isPending}
                        >
                            Save
                        </LoadingButton>
                    </Stack>
                </form>
            </Paper>
        </Container>
    );
};

export default UpdateNicknamePage;
