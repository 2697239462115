import type { ListItemTextProps } from "@mui/material";
import { Box, ListItemText } from "@mui/material";
import type { ReactNode } from "react";

type Props = Omit<ListItemTextProps, "disableTypography" | "children"> & {
    primary: ReactNode;
    secondary?: ReactNode;
};

export const WrappingListItemText = ({ primary, secondary, sx, ...rest }: Props): ReactNode => {
    return (
        <ListItemText
            {...rest}
            sx={{ display: { xs: "block", sm: "flex" }, alignItems: "center", ...sx }}
            disableTypography
            primary={<Box sx={{ flexBasis: 150, mr: 3 }}>{primary}</Box>}
            secondary={secondary ? <Box>{secondary}</Box> : undefined}
        />
    );
};
