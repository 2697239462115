import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useUploadPictureMutation } from "@mutations/profile.js";
import { useSnackbar } from "notistack";
import { type ReactNode, useCallback, useState } from "react";
import type { Area } from "react-easy-crop";
import Cropper from "react-easy-crop";

const cropPicture = async (source: string, crop: Area): Promise<Blob> => {
    const image = new Image();
    image.src = source;

    await new Promise((resolve, reject) => {
        image.addEventListener("load", resolve);
        image.addEventListener("error", reject);
    });

    const size = Math.min(500, crop.width);

    const canvas = document.createElement("canvas");
    canvas.width = size;
    canvas.height = size;
    const context = canvas.getContext("2d");

    if (!context) {
        throw new Error("Failed to get 2d context");
    }

    context.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, size, size);

    return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
            if (!blob) {
                reject(new Error("Could not create blob"));
                return;
            }

            resolve(blob);
        });
    });
};

type Props = {
    imageSource: string;
    onClose: () => void;
    onCancel: () => void;
};

const CropPfp = ({ imageSource, onClose, onCancel }: Props): ReactNode => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [finalCrop, setFinalCrop] = useState<Area | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const uploadPfpMutation = useUploadPictureMutation();

    const handleCropComplete = useCallback((_croppedArea: Area, croppedAreaPixels: Area) => {
        setFinalCrop(croppedAreaPixels);
    }, []);

    const handleApply = async () => {
        if (!(imageSource && finalCrop)) {
            return;
        }

        const picture = await cropPicture(imageSource, finalCrop);

        uploadPfpMutation.mutate(picture, {
            onError: (error) => {
                enqueueSnackbar(error.message, { variant: "error" });
            },
            onSuccess: () => {
                enqueueSnackbar("Profile picture has been updated", { variant: "success" });
                onClose();
            },
        });
    };

    return (
        <>
            <DialogTitle>Crop Picture</DialogTitle>
            <DialogContent dividers sx={{ p: 0 }}>
                <Box
                    sx={{
                        position: "relative",
                        minHeight: "444px",
                        height: "100%",
                    }}
                >
                    <Cropper
                        aspect={1}
                        showGrid={true}
                        zoomSpeed={2}
                        minZoom={1}
                        maxZoom={3}
                        image={imageSource}
                        onCropChange={setCrop}
                        onCropComplete={handleCropComplete}
                        crop={crop}
                        onZoomChange={setZoom}
                        zoom={zoom}
                        cropShape="rect"
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button disabled={uploadPfpMutation.isPending} onClick={onCancel}>
                    Cancel
                </Button>
                <LoadingButton onClick={handleApply} loading={uploadPfpMutation.isPending}>
                    Apply
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default CropPfp;
