import { useCsrf } from "@components/CsrfProvider/index.js";
import { useCallback } from "react";

const useApiFetch = (): typeof fetch => {
    const csrf = useCsrf();

    return useCallback(
        async (input, init) => {
            const csrfToken = await csrf.csrfToken;

            const innerInit = init ?? {};

            innerInit.credentials = "include";
            innerInit.headers = new Headers(innerInit.headers);
            innerInit.headers.set("X-FV-CSRF", csrfToken);

            const response = await fetch(input, init);

            if (response.ok) {
                return response;
            }

            const newCsrfToken = response.headers.get("X-FV-CSRF");

            if (!newCsrfToken) {
                return response;
            }

            csrf.setCsrfToken(newCsrfToken);
            innerInit.headers.set("X-FV-CSRF", newCsrfToken);

            return await fetch(input, init);
        },
        [csrf],
    );
};

export default useApiFetch;
