import ErrorAlert from "@components/ErrorAlert/index.ts";
import { useSessionRefresh } from "@components/SessionRefreshProvider/SessionRefreshProvider.js";
import { zodResolver } from "@hookform/resolvers/zod";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import {
    Alert,
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    LinearProgress,
    Link,
    Paper,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import {
    type TotpFlow,
    useCreateTotpFlowMutation,
    useDeleteTotpMutation,
    useUpdateTotpFlowMutation,
} from "@mutations/totp.js";
import { useCredentialsStatusQuery } from "@queries/credentials.js";
import { sessionRefreshRequired } from "@utils/api.ts";
import { RhfTextField } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import { QRCodeSVG } from "qrcode.react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { z } from "zod";

const androidUrl =
    "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2";
const iosUrl = "https://apps.apple.com/us/app/google-authenticator/id388497605";

const schema = z.object({
    passcode: z.string().trim().min(1),
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

const AuthenticatorAppPage = (): JSX.Element => {
    const [totpFlow, setTotpFlow] = useState<TotpFlow | null>(null);
    const credentialsStatusQuery = useCredentialsStatusQuery();
    const createTotpFlowMutation = useCreateTotpFlowMutation();
    const submitTotpFlowMutation = useUpdateTotpFlowMutation();
    const deleteTotpMutation = useDeleteTotpMutation();
    const refreshSession = useSessionRefresh();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate();
    const [dialogOpen, setDialogOpen] = useState(false);

    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
    });

    const handleCreateFlow = () => {
        createTotpFlowMutation.mutate(undefined, {
            onSuccess: (flow) => {
                form.reset({ passcode: "" });
                setTotpFlow(flow);
                setDialogOpen(true);
            },
            onError: (error) => {
                if (!sessionRefreshRequired(error)) {
                    enqueueSnackbar(error.message, { variant: "error" });
                    return;
                }

                refreshSession()
                    .then((refreshed) => {
                        if (refreshed) {
                            handleCreateFlow();
                        }
                    })
                    .catch(console.error);
            },
        });
    };

    const handleSubmit = (values: TransformedValues) => {
        if (!totpFlow) {
            return;
        }

        submitTotpFlowMutation.mutate(
            { ...values, flowId: totpFlow.data.id },
            {
                onSuccess: () => {
                    enqueueSnackbar("Authenticator has been linked", { variant: "success" });
                    navigate("/settings");
                },
                onError: (error) => {
                    enqueueSnackbar(error.message, { variant: "error" });
                },
            },
        );
    };

    const copySecret = () => {
        if (!totpFlow) {
            return;
        }

        navigator.clipboard
            .writeText(totpFlow.data.secret)
            .then(() => {
                enqueueSnackbar("Secret copied to clipboard", { variant: "success" });
            })
            .catch(() => {
                enqueueSnackbar("Failed to copy secret to clipboard", { variant: "error" });
            });
    };

    const handleDelete = () => {
        deleteTotpMutation.mutate(undefined, {
            onSuccess: () => {
                enqueueSnackbar("Authenticator has been unlinked", { variant: "success" });
                navigate("/settings");
            },
            onError: (error) => {
                if (!sessionRefreshRequired(error)) {
                    return;
                }

                refreshSession()
                    .then((refreshed) => {
                        if (refreshed) {
                            handleDelete();
                        }
                    })
                    .catch(console.error);
            },
        });
    };

    return (
        <Container maxWidth="sm">
            <Stack direction="row" spacing={2} alignItems="center">
                <IconButton component={RouterLink} to="/settings">
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h5">Authenticator App</Typography>
            </Stack>

            <Divider sx={{ mt: 1, mb: 3 }} />

            <Paper variant="outlined" sx={{ p: { xs: 2, sm: 4 } }}>
                <Typography variant="body2" sx={{ mb: 4 }}>
                    Use any TOTP authenticator like Google Authenticator (
                    <Link href={androidUrl} target="_blank" rel="noreferrer">
                        Android
                    </Link>
                    {" / "}
                    <Link href={iosUrl} target="_blank" rel="noreferrer">
                        iOS
                    </Link>
                    ) to protect your account.
                </Typography>

                {!credentialsStatusQuery.data ? (
                    <LinearProgress />
                ) : (
                    <>
                        {credentialsStatusQuery.data.totpConfiguredSince && (
                            <Alert severity="info" sx={{ mb: 4 }}>
                                You have already linked an authenticator. Linking another
                                authenticator will invalidate the previous one.
                            </Alert>
                        )}

                        <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                            <LoadingButton
                                variant="outlined"
                                onClick={handleCreateFlow}
                                loading={createTotpFlowMutation.isPending}
                                sx={{ flexGrow: 1 }}
                            >
                                Link authenticator
                            </LoadingButton>

                            {credentialsStatusQuery.data.totpConfiguredSince && (
                                <LoadingButton
                                    variant="outlined"
                                    onClick={handleDelete}
                                    loading={deleteTotpMutation.isPending}
                                    sx={{ flexGrow: 1 }}
                                >
                                    Unlink authenticator
                                </LoadingButton>
                            )}
                        </Stack>
                    </>
                )}

                <Dialog
                    open={dialogOpen}
                    onClose={() => {
                        setDialogOpen(false);
                    }}
                    maxWidth="xs"
                    fullWidth
                    fullScreen={fullScreen}
                >
                    <Box
                        component="form"
                        noValidate
                        onSubmit={form.handleSubmit(handleSubmit)}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                        }}
                    >
                        <DialogTitle>Configure TOTP</DialogTitle>
                        <DialogContent>
                            <Stack alignItems="center" spacing={2}>
                                {totpFlow && (
                                    <QRCodeSVG
                                        value={totpFlow.meta?.url as string}
                                        includeMargin
                                        size={300}
                                    />
                                )}

                                <Stack spacing={2}>
                                    <Card variant="outlined">
                                        <CardActionArea onClick={copySecret}>
                                            <CardContent>
                                                <Typography fontFamily="monospace">
                                                    {totpFlow?.data.secret}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>

                                    <Typography>
                                        Scan the QR code above and enter a generated passcode for
                                        confirmation below or copy the secret to set it up manually.
                                    </Typography>

                                    {submitTotpFlowMutation.isError &&
                                        !sessionRefreshRequired(submitTotpFlowMutation.error) && (
                                            <ErrorAlert
                                                error={submitTotpFlowMutation.error}
                                                sx={{ mb: 4 }}
                                            />
                                        )}

                                    <RhfTextField
                                        control={form.control}
                                        label="Passcode"
                                        name="passcode"
                                        autoComplete="one-time-code"
                                        fullWidth
                                        required
                                    />
                                </Stack>
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setDialogOpen(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button variant="contained" type="submit">
                                Confirm
                            </Button>
                        </DialogActions>
                    </Box>
                </Dialog>
            </Paper>
        </Container>
    );
};

export default AuthenticatorAppPage;
