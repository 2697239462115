import { JsonApiError } from "jsonapi-zod-query";

export const apiUrl = (path: string): URL => new URL(path, import.meta.env.VITE_APP_API_URL);

export const sessionRefreshRequired = (error: unknown) => {
    if (!(error instanceof JsonApiError)) {
        return false;
    }

    return error.errors.some((error) => error.code === "re_authentication_required");
};

export const flowExpired = (error: unknown) => {
    if (!(error instanceof JsonApiError)) {
        return false;
    }

    return error.errors.some((error) => error.code === "flow_expired");
};

export const getFirstPointerErrorMessage = (
    error: unknown,
    pointer: string,
    source: "title" | "detail" = "title",
) => {
    if (!(error instanceof JsonApiError)) {
        throw error;
    }

    const jsonApiError = error.errors.find((error) => error.source?.pointer === pointer);

    if (!jsonApiError) {
        throw error;
    }

    const message = jsonApiError[source];

    if (message === undefined) {
        throw new Error(`JSON:API error does not contain ${source} attribute`);
    }

    return message;
};
