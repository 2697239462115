import logoShort from "@assets/logo-short.svg";
import { Box, Paper, Stack, Typography } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    title: string;
    showLogo?: boolean;
    children: ReactNode;
};

const CardScaffold = ({ title, showLogo, children }: Props): ReactNode => {
    return (
        <Box
            sx={{
                minHeight: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: { xs: "stretch", sm: "center" },
                p: { xs: 0, sm: 4 },
            }}
        >
            <Paper
                variant="outlined"
                sx={(theme) => ({
                    width: { xs: "100%", sm: 450 },
                    minHeight: { xs: "100vh", sm: "auto" },
                    border: { xs: "none", sm: `1px solid ${theme.palette.divider}` },
                    borderRadius: { xs: 0, sm: `${theme.shape.borderRadius}px` },
                    p: 4,
                })}
            >
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h4" sx={{ mb: 4 }}>
                        {title}
                    </Typography>
                    {showLogo && (
                        <Box component="img" src={logoShort} alt="Furvester" sx={{ height: 40 }} />
                    )}
                </Stack>

                {children}
            </Paper>
        </Box>
    );
};

export default CardScaffold;
