import ErrorAlert from "@components/ErrorAlert/index.ts";
import { RhfPasswordField } from "@components/Rhf/index.js";
import { CardScaffold } from "@components/Scaffold/index.js";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Divider, Link, Stack, Typography } from "@mui/material";
import { useRegistrationMutation } from "@mutations/registration.js";
import { RhfTextField } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { z } from "zod";

const schema = z.object({
    nickname: z.string().min(2),
    emailAddress: z.string().email(),
    password: z.string().min(8),
});

type FormValues = z.infer<typeof schema>;

const RegistrationPage = (): ReactNode => {
    const registrationMutation = useRegistrationMutation();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const form = useForm<FormValues>({
        resolver: zodResolver(schema),
    });

    const handleSubmit = (values: FormValues) => {
        registrationMutation.mutate(values, {
            onSuccess: (result) => {
                enqueueSnackbar(
                    "Your account has been successfully created, please verify your email address.",
                    { variant: "success" },
                );
                navigate(`/verification?flowId=${result.verificationFlowId}`);
            },
        });
    };

    return (
        <CardScaffold title="Create an account">
            <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
                {registrationMutation.isError && (
                    <ErrorAlert error={registrationMutation.error} sx={{ mb: 4 }} />
                )}

                <Stack spacing={2}>
                    <RhfTextField
                        control={form.control}
                        label="Nickname"
                        name="nickname"
                        required
                        autoComplete="nickname"
                        disabled={registrationMutation.isPending}
                    />
                    <RhfTextField
                        control={form.control}
                        label="Email address"
                        name="emailAddress"
                        type="email"
                        required
                        autoComplete="username"
                        disabled={registrationMutation.isPending}
                    />
                    <RhfPasswordField
                        control={form.control}
                        label="Password"
                        name="password"
                        required
                        autoComplete="new-password"
                        disabled={registrationMutation.isPending}
                    />
                </Stack>

                <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 4 }}
                    loading={registrationMutation.isPending}
                >
                    Sign up
                </LoadingButton>
            </form>

            <Divider sx={{ my: 4 }} />
            <Typography variant="body2" sx={{ textAlign: "center" }}>
                Already have an account?{" "}
                <Link component={RouterLink} to="/login">
                    Sign in
                </Link>
            </Typography>
        </CardScaffold>
    );
};

export default RegistrationPage;
