import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, InputAdornment } from "@mui/material";
import { useUpdateLoginFlowMutation } from "@mutations/login.js";
import { flowExpired, getFirstPointerErrorMessage } from "@utils/api.ts";
import { RhfTextField } from "mui-rhf-integration";
import type { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
    totpPasscode: z.string().min(1),
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

type Props = {
    flowId: string;
    onSuccess: () => void;
    onExpired: () => void;
};

const TotpCredentialForm = ({ flowId, onSuccess, onExpired }: Props): ReactNode => {
    const updateLoginFlowMutation = useUpdateLoginFlowMutation();

    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
    });

    const handleSubmit = (values: TransformedValues) => {
        updateLoginFlowMutation.mutate(
            { flowId, ...values },
            {
                onSuccess,
                onError: (error) => {
                    if (flowExpired(error)) {
                        onExpired();
                        return;
                    }

                    const message = getFirstPointerErrorMessage(
                        error,
                        "data.attributes.totpPasscode",
                    );
                    form.setError("totpPasscode", { message });
                },
            },
        );
    };

    return (
        <Box component="form" noValidate onSubmit={form.handleSubmit(handleSubmit)}>
            <RhfTextField
                control={form.control}
                label="Authenticator code"
                name="totpPasscode"
                fullWidth
                autoComplete="one-time-code"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button type="submit">Continue</Button>
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    );
};

export default TotpCredentialForm;
