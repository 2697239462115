import { Dialog, useMediaQuery, useTheme } from "@mui/material";
import { type ReactNode, useEffect, useState } from "react";
import CropPfp from "./CropPfp.tsx";
import CurrentPfp from "./CurrentPfp.tsx";

type Props = {
    open: boolean;
    onClose: () => void;
};

const PfpDialog = ({ open, onClose }: Props): ReactNode => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [imageSource, setImageSource] = useState<string | null>(null);

    useEffect(() => {
        if (open) {
            setImageSource(null);
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth fullScreen={fullScreen}>
            {!imageSource ? (
                <CurrentPfp
                    onClose={onClose}
                    onImageSelect={(imageSource) => {
                        setImageSource(imageSource);
                    }}
                />
            ) : (
                <CropPfp
                    onCancel={() => {
                        setImageSource(null);
                    }}
                    onClose={onClose}
                    imageSource={imageSource}
                />
            )}
        </Dialog>
    );
};

export default PfpDialog;
