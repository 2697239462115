import PfpDialog from "@components/PfpDialog/index.js";
import { useSession } from "@components/Scaffold/index.js";
import UserAvatar from "@components/UserAvatar/UserAvatar.js";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import EditIcon from "@mui/icons-material/Edit";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    List,
    ListItem,
    ListItemButton,
    Typography,
} from "@mui/material";
import { type ReactNode, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { WrappingListItemText } from "./WrappingListItemText.js";

const ProfileCard = (): ReactNode => {
    const session = useSession();
    const [pfpDialogOpen, setPfpDialogOpen] = useState(false);

    return (
        <Card>
            <CardHeader title="Profile" />
            <CardContent sx={{ p: 0 }}>
                <List disablePadding>
                    <ListItem disablePadding>
                        <ListItemButton component={RouterLink} to="/settings/nickname">
                            <WrappingListItemText
                                primary={<Typography variant="body2">Nickname</Typography>}
                                secondary={session.identity.nickname}
                            />
                            <EditIcon />
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemButton component={RouterLink} to="/settings/email-address">
                            <WrappingListItemText
                                primary={<Typography variant="body2">Email address</Typography>}
                                secondary={session.identity.emailAddress}
                            />
                            <EditIcon />
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => {
                                setPfpDialogOpen(true);
                            }}
                        >
                            <WrappingListItemText
                                primary={<Typography variant="body2">Profile picture</Typography>}
                                secondary={
                                    <Typography variant="body2" sx={{ color: "grey.400" }}>
                                        Used on the website and badge
                                    </Typography>
                                }
                            />
                            <Box
                                sx={{
                                    width: 60,
                                    height: 60,
                                    borderRadius: "50%",
                                    overflow: "hidden",
                                    position: "relative",
                                }}
                            >
                                <UserAvatar identity={session.identity} size={60} />
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        position: "absolute",
                                        bottom: 0,
                                        width: "100%",
                                        py: "1px",
                                        backgroundColor: "rgba(32, 33, 36, 0.6)",
                                        color: "rgba(255, 255, 255, 0.7)",
                                    }}
                                >
                                    <CameraAltIcon fontSize="small" />
                                </Box>
                            </Box>
                        </ListItemButton>
                    </ListItem>
                </List>
            </CardContent>

            <PfpDialog
                open={pfpDialogOpen}
                onClose={() => {
                    setPfpDialogOpen(false);
                }}
            />
        </Card>
    );
};

export default ProfileCard;
