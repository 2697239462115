import ErrorAlert from "@components/ErrorAlert";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import { useUpdateRecoveryFlowMutation } from "@mutations/recovery.js";
import { RhfTextField } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

const schema = z.object({
    code: z.string().trim().min(1),
});

type FormValues = z.infer<typeof schema>;

type Props = {
    flowId: string;
};

const UpdateRecoveryFlowForm = ({ flowId }: Props): ReactNode => {
    const commitRecoveryMutation = useUpdateRecoveryFlowMutation();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code");

    const form = useForm<FormValues>({
        resolver: zodResolver(schema),
        defaultValues: { code: code ?? "" },
    });

    const handleSubmit = (values: FormValues) => {
        commitRecoveryMutation.mutate(
            { flowId, ...values },
            {
                onSuccess: () => {
                    enqueueSnackbar(
                        "You have successfully recovered your account, please change your password.",
                        { variant: "success" },
                    );
                    navigate("/settings");
                },
            },
        );
    };

    return (
        <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
            {commitRecoveryMutation.isError && (
                <ErrorAlert error={commitRecoveryMutation.error} sx={{ mb: 4 }} />
            )}

            <Stack spacing={2}>
                <RhfTextField
                    control={form.control}
                    label="Code"
                    name="code"
                    required
                    autoComplete="off"
                    disabled={commitRecoveryMutation.isPending}
                />
            </Stack>

            <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 4 }}
                loading={commitRecoveryMutation.isPending}
            >
                Verify
            </LoadingButton>
        </form>
    );
};

export default UpdateRecoveryFlowForm;
