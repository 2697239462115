import useApiFetch from "@hooks/useApiFetch.js";
import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiUrl } from "@utils/api.js";
import { handleJsonApiError } from "jsonapi-zod-query";

export const useGenerateBackupMutation = (): UseMutationResult<void, Error, void> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async () => {
            const response = await fetch(
                apiUrl("/identity/self-service/identity/credentials/backup").toString(),
                {
                    method: "POST",
                    headers: {
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["credentials", "status"] });
            await queryClient.invalidateQueries({ queryKey: ["credentials", "backup"] });
        },
    });
};
