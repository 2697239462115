import { DateTimeFormatter, ZoneId } from "@js-joda/core";
import { Locale } from "@js-joda/locale_en-us";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FlightIcon from "@mui/icons-material/Flight";
import LockIcon from "@mui/icons-material/Lock";
import PasswordIcon from "@mui/icons-material/Password";
import ShieldIcon from "@mui/icons-material/Shield";
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Typography,
} from "@mui/material";
import { useCredentialsStatusQuery } from "@queries/credentials.js";
import type { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import { WrappingListItemText } from "./WrappingListItemText.js";

const dateFormatter = DateTimeFormatter.ofPattern("d MMMM y").withLocale(Locale.US);

const SecurityCard = (): ReactNode => {
    const credentialsStatusQuery = useCredentialsStatusQuery();

    if (!credentialsStatusQuery.data) {
        return (
            <Card>
                <CardHeader title="Security" />
                <CardContent sx={{ p: 0 }}>
                    <LinearProgress />
                </CardContent>
            </Card>
        );
    }

    const { data } = credentialsStatusQuery;
    const lastPasswordChange = data.lastPasswordChange.withZoneSameInstant(ZoneId.systemDefault());
    const totpConfiguredSince = data.totpConfiguredSince?.withZoneSameInstant(
        ZoneId.systemDefault(),
    );
    const { backupCodesRemaining, webAuthnKeysRegistered } = data;

    return (
        <Card>
            <CardHeader title="Security" />
            <CardContent sx={{ p: 0 }}>
                <List disablePadding>
                    <ListItem disablePadding>
                        <ListItemButton component={RouterLink} to="/settings/password">
                            <ListItemIcon>
                                <PasswordIcon />
                            </ListItemIcon>
                            <WrappingListItemText
                                primary="Password"
                                secondary={
                                    <Typography variant="body2">
                                        Last changed {lastPasswordChange.format(dateFormatter)}
                                    </Typography>
                                }
                            />
                            <ArrowForwardIosIcon />
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemButton component={RouterLink} to="/settings/security-keys">
                            <ListItemIcon>
                                <LockIcon />
                            </ListItemIcon>
                            <WrappingListItemText
                                primary="Security keys"
                                secondary={
                                    webAuthnKeysRegistered > 0 ? (
                                        <Typography variant="body2" color="success.main">
                                            {webAuthnKeysRegistered} key
                                            {webAuthnKeysRegistered > 1 && "s"}
                                        </Typography>
                                    ) : (
                                        <Typography variant="body2" color="warning.main">
                                            Not configured
                                        </Typography>
                                    )
                                }
                            />
                            <ArrowForwardIosIcon />
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemButton component={RouterLink} to="/settings/authenticator">
                            <ListItemIcon>
                                <ShieldIcon />
                            </ListItemIcon>
                            <WrappingListItemText
                                primary="Authenticator app"
                                secondary={
                                    totpConfiguredSince ? (
                                        <Typography variant="body2" color="success.main">
                                            Active since {totpConfiguredSince.format(dateFormatter)}
                                        </Typography>
                                    ) : (
                                        <Typography variant="body2" color="warning.main">
                                            Not configured
                                        </Typography>
                                    )
                                }
                            />
                            <ArrowForwardIosIcon />
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem disablePadding>
                        <ListItemButton component={RouterLink} to="/settings/backup">
                            <ListItemIcon>
                                <FlightIcon />
                            </ListItemIcon>
                            <WrappingListItemText
                                primary="Backup codes"
                                secondary={
                                    backupCodesRemaining === null ? (
                                        <Typography variant="body2" color="warning.main">
                                            Not configured
                                        </Typography>
                                    ) : (
                                        <Typography
                                            variant="body2"
                                            color={
                                                backupCodesRemaining > 2
                                                    ? "success.main"
                                                    : backupCodesRemaining > 0
                                                      ? "warning.main"
                                                      : "error.main"
                                            }
                                        >
                                            {backupCodesRemaining} remaining
                                        </Typography>
                                    )
                                }
                            />
                            <ArrowForwardIosIcon />
                        </ListItemButton>
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    );
};

export default SecurityCard;
