import useApiFetch from "@hooks/useApiFetch.js";
import type {
    AuthenticationResponseJSON,
    PublicKeyCredentialRequestOptionsJSON,
} from "@simplewebauthn/types";
import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiUrl } from "@utils/api.js";
import { handleJsonApiError } from "jsonapi-zod-query";
import { z } from "zod";

type CreateLoginFlowValues = {
    emailAddress: string;
    password: string;
};

const createLoginFlowResultSchema = z.object({
    data: z.object({
        id: z.string(),
    }),
    meta: z.object({
        hasTotpCredential: z.boolean(),
        hasBackupCredential: z.boolean(),
        webAuthnOptions: z.custom<PublicKeyCredentialRequestOptionsJSON | null>(),
    }),
});

export type LoginResult = z.output<typeof createLoginFlowResultSchema>;

export const useCreateLoginFlowMutation = (): UseMutationResult<
    LoginResult | null,
    Error,
    CreateLoginFlowValues
> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(apiUrl("/identity/self-service/flows/login").toString(), {
                method: "POST",
                body: JSON.stringify({
                    data: {
                        type: "login_flow",
                        attributes: values,
                    },
                }),
                headers: {
                    "Content-Type": "application/vnd.api+json",
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);

            if (response.status === 204) {
                return null;
            }

            const raw = await response.json();
            return createLoginFlowResultSchema.parse(raw);
        },
        onSuccess: async (result) => {
            if (!result) {
                await queryClient.resetQueries({ queryKey: ["sessions", "current"] });
            }
        },
    });
};

type UpdateLoginFlowValues = {
    flowId: string;
    webAuthnResponse?: AuthenticationResponseJSON;
    totpPasscode?: string;
    backupCode?: string;
};

export const useUpdateLoginFlowMutation = (): UseMutationResult<
    void,
    Error,
    UpdateLoginFlowValues
> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const { flowId, ...rest } = values;

            const response = await fetch(
                apiUrl(`/identity/self-service/flows/login/${flowId}`).toString(),
                {
                    method: "PATCH",
                    body: JSON.stringify({
                        data: {
                            id: flowId,
                            type: "login_flow",
                            attributes: rest,
                        },
                    }),
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.resetQueries({ queryKey: ["sessions", "current"] });
        },
    });
};
