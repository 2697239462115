import useApiFetch from "@hooks/useApiFetch.js";
import type { RegistrationResponseJSON } from "@simplewebauthn/types";
import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiUrl } from "@utils/api.js";
import { createResourceSelector, handleJsonApiError } from "jsonapi-zod-query";

type CreateWebAuthnFlowValues = {
    displayName: string;
};

const webAuthnFlowSelector = createResourceSelector({
    type: "webauthn_flow",
});

export type WebAuthnFlow = ReturnType<typeof webAuthnFlowSelector>;

export const useCreateWebAuthnFlowMutation = (): UseMutationResult<
    WebAuthnFlow,
    Error,
    CreateWebAuthnFlowValues
> => {
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl("/identity/self-service/flows/webauthn").toString(),
                {
                    method: "POST",
                    body: JSON.stringify({
                        data: {
                            type: "webauthn_flow",
                            attributes: values,
                        },
                    }),
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);

            return webAuthnFlowSelector(await response.json());
        },
    });
};

export type UpdateWebAuthnFlowValues = {
    flowId: string;
    response: RegistrationResponseJSON;
};

export const useUpdateWebAuthnFlowMutation = (): UseMutationResult<
    void,
    Error,
    UpdateWebAuthnFlowValues
> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const { flowId, ...rest } = values;

            const response = await fetch(
                apiUrl(`/identity/self-service/flows/webauthn/${flowId}`).toString(),
                {
                    method: "PATCH",
                    body: JSON.stringify({
                        data: {
                            id: flowId,
                            type: "webauthn_flow",
                            attributes: rest,
                        },
                    }),
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["credentials", "status"] });
            await queryClient.invalidateQueries({ queryKey: ["credentials", "webauthn"] });
        },
    });
};

type DeleteWebauthnValues = {
    id: string;
};

export const useDeleteWebauthnMutation = (): UseMutationResult<
    void,
    Error,
    DeleteWebauthnValues
> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(
                    `/identity/self-service/identity/credentials/webauthn/${values.id}`,
                ).toString(),
                {
                    method: "DELETE",
                    headers: {
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["credentials", "status"] });
            await queryClient.invalidateQueries({ queryKey: ["credentials", "webauthn"] });
        },
    });
};
