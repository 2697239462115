import useApiFetch from "@hooks/useApiFetch.js";
import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiUrl } from "@utils/api.js";
import { handleJsonApiError } from "jsonapi-zod-query";

type RefreshValues = {
    password: string;
};

export const useSessionRefreshMutation = (): UseMutationResult<unknown, Error, RefreshValues> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(apiUrl("/identity/sessions/current/refresh").toString(), {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);

            return response.json();
        },
        onSuccess: (session) => {
            queryClient.setQueryData(["sessions", "current"], session);
        },
    });
};

export const useDeleteSessionMutation = (): UseMutationResult<void, Error, void> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async () => {
            const response = await fetch(apiUrl("/identity/sessions/current").toString(), {
                method: "DELETE",
                headers: {
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.resetQueries({ queryKey: ["sessions", "current"] });
        },
    });
};

export const useDeleteOtherSessionsMutation = (): UseMutationResult<void, Error, void> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async () => {
            const response = await fetch(apiUrl("/identity/sessions").toString(), {
                method: "DELETE",
                headers: {
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["sessions"], exact: true });
        },
    });
};
