import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { apiUrl } from "@utils/api.js";
import {
    createDataSelector,
    createNullableResourceSelector,
    createResourceCollectionSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";
import { zj } from "zod-joda";

const credentialsStatusSchema = z.object({
    meta: z.object({
        lastPasswordChange: zj.zonedDateTime(),
        webAuthnKeysRegistered: z.number(),
        totpConfiguredSince: zj.zonedDateTime().nullable(),
        backupCodesRemaining: z.number().nullable(),
    }),
});

const credentialsStatusSelector = (raw: unknown) => credentialsStatusSchema.parse(raw).meta;

export type CredentialsStatus = ReturnType<typeof credentialsStatusSelector>;

export const useCredentialsStatusQuery = (): UseQueryResult<CredentialsStatus | null> => {
    return useQuery({
        queryKey: ["credentials", "status"],
        queryFn: async ({ signal }) => {
            const response = await fetch(
                apiUrl("/identity/self-service/identity/credentials/status").toString(),
                {
                    signal,
                    credentials: "include",
                    headers: {
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);

            return response.json();
        },
        select: credentialsStatusSelector,
    });
};

const backupCredentialAttributesSchema = z.object({
    codes: z.array(
        z.object({
            code: z.string(),
            usedAt: zj.zonedDateTime().nullable(),
        }),
    ),
});

const backupCredentialSelector = createDataSelector(
    createNullableResourceSelector({
        type: "backup_credential",
        attributesSchema: backupCredentialAttributesSchema,
    }),
);

export type BackupCredential = ReturnType<typeof backupCredentialSelector>;

export const useBackupCredentialQuery = (): UseQueryResult<BackupCredential> => {
    return useQuery({
        queryKey: ["credentials", "backup"],
        queryFn: async ({ signal }) => {
            const response = await fetch(
                apiUrl("/identity/self-service/identity/credentials/backup").toString(),
                {
                    signal,
                    credentials: "include",
                    headers: {
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);

            return response.json();
        },
        select: backupCredentialSelector,
    });
};

const webAuthnCredentialsAttributesSchema = z.object({
    displayName: z.string(),
});

const webAuthnCredentialsSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "webauthn_credential",
        attributesSchema: webAuthnCredentialsAttributesSchema,
    }),
);

export type WebAuthnCredential = ReturnType<typeof webAuthnCredentialsSelector>[number];

export const useWebAuthnCredentialsQuery = (): UseQueryResult<WebAuthnCredential[]> => {
    return useQuery({
        queryKey: ["credentials", "webauthn"],
        queryFn: async ({ signal }) => {
            const response = await fetch(
                apiUrl("/identity/self-service/identity/credentials/webauthn").toString(),
                {
                    signal,
                    credentials: "include",
                    headers: {
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);

            return response.json();
        },
        select: webAuthnCredentialsSelector,
    });
};
