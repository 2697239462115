import useApiFetch from "@hooks/useApiFetch.js";
import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiUrl } from "@utils/api.js";
import { handleJsonApiError } from "jsonapi-zod-query";
import { z } from "zod";

type UpdateEmailAddressValues = {
    emailAddress: string;
};

const updateEmailAddressResultSchema = z.object({
    meta: z.object({
        verificationFlowId: z.string(),
    }),
});

type UpdateEmailAddressResultMeta = z.output<typeof updateEmailAddressResultSchema>["meta"];

export const useUpdateEmailAddressMutation = (): UseMutationResult<
    UpdateEmailAddressResultMeta | null,
    Error,
    UpdateEmailAddressValues
> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(
                    "/identity/self-service/identity/credentials/update-email-address",
                ).toString(),
                {
                    method: "POST",
                    body: JSON.stringify(values),
                    headers: {
                        "Content-Type": "application/json",
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);

            if (response.status === 204) {
                return null;
            }

            const raw = await response.json();
            return updateEmailAddressResultSchema.parse(raw).meta;
        },

        onSuccess: () => {
            queryClient.removeQueries({ queryKey: ["sessions", "current"] });
        },
    });
};

type UpdatePasswordValues = {
    password: string;
};

export const useUpdatePasswordMutation = (): UseMutationResult<
    void,
    Error,
    UpdatePasswordValues
> => {
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl("/identity/self-service/identity/credentials/update-password").toString(),
                {
                    method: "POST",
                    body: JSON.stringify(values),
                    headers: {
                        "Content-Type": "application/json",
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);
        },
    });
};
