import ErrorAlert from "@components/ErrorAlert/index.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import { useCreateRecoveryFlowMutation } from "@mutations/recovery.js";
import { RhfTextField } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

const schema = z.object({
    emailAddress: z.string().email(),
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

const CreateRecoveryFlowForm = (): ReactNode => {
    const [, setSearchParams] = useSearchParams();
    const createRecoveryFlowMutation = useCreateRecoveryFlowMutation();
    const { enqueueSnackbar } = useSnackbar();

    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
    });

    const handleSubmit = (values: TransformedValues) => {
        createRecoveryFlowMutation.mutate(values, {
            onSuccess: (flow) => {
                enqueueSnackbar("A recovery code has been sent to your email address.", {
                    variant: "success",
                });
                setSearchParams({ flowId: flow.id });
            },
        });
    };

    return (
        <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
            {createRecoveryFlowMutation.isError && (
                <ErrorAlert error={createRecoveryFlowMutation.error} sx={{ mb: 4 }} />
            )}

            <Stack spacing={2}>
                <RhfTextField
                    control={form.control}
                    label="Email address"
                    name="emailAddress"
                    type="email"
                    required
                    autoComplete="username"
                    disabled={createRecoveryFlowMutation.isPending}
                />
            </Stack>

            <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 4 }}
                loading={createRecoveryFlowMutation.isPending}
            >
                Request code
            </LoadingButton>
        </form>
    );
};

export default CreateRecoveryFlowForm;
