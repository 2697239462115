import useApiFetch from "@hooks/useApiFetch.js";
import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiUrl } from "@utils/api.js";
import { handleJsonApiError } from "jsonapi-zod-query";

export const useUploadPictureMutation = (): UseMutationResult<void, Error, Blob> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (image) => {
            const response = await fetch(
                apiUrl("/identity/self-service/identity/profile/update-picture").toString(),
                {
                    method: "POST",
                    body: image,
                    headers: {
                        "Content-Type": "image/png",
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["sessions", "current"] });
        },
    });
};

export const useDeletePictureMutation = (): UseMutationResult<void, Error, void> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async () => {
            const response = await fetch(
                apiUrl("/identity/self-service/identity/profile/picture").toString(),
                {
                    method: "DELETE",
                    headers: {
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["sessions", "current"] });
        },
    });
};

type UpdateNicknameValues = {
    nickname: string;
};

export const useUpdateNicknameMutation = (): UseMutationResult<
    void,
    Error,
    UpdateNicknameValues
> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl("/identity/self-service/identity/profile/update-nickname").toString(),
                {
                    method: "POST",
                    body: JSON.stringify(values),
                    headers: {
                        "Content-Type": "application/json",
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: () => {
            queryClient.removeQueries({ queryKey: ["sessions", "current"] });
        },
    });
};
