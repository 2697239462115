import CsrfProvider from "@components/CsrfProvider/index.js";
import MultiProvider from "@components/MultiProvider/index.ts";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { router } from "./router.js";

const container = document.getElementById("root");

if (!container) {
    throw new Error("Root container missing");
}

const theme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#d35015",
        },
    },
});

const queryClient = new QueryClient();

const root = createRoot(container);

root.render(
    <StrictMode>
        <MultiProvider
            providerCreators={[
                (children) => (
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        {children}
                    </ThemeProvider>
                ),
                (children) => <CsrfProvider>{children}</CsrfProvider>,
                (children) => (
                    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
                ),
                (children) => <SnackbarProvider>{children}</SnackbarProvider>,
            ]}
        >
            <RouterProvider router={router} />
        </MultiProvider>
    </StrictMode>,
);
