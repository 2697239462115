import { CardScaffold } from "@components/Scaffold/index.js";
import { Divider, Link, Typography } from "@mui/material";
import type { ReactNode } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import CreateRecoveryFlowForm from "./CreateRecoveryFlowForm.js";
import UpdateRecoveryFlowForm from "./UpdateRecoveryFlowForm.js";

const RecoveryPage = (): ReactNode => {
    const [searchParams] = useSearchParams();
    const flowId = searchParams.get("flowId");

    return (
        <CardScaffold title="Recover account">
            {flowId ? <UpdateRecoveryFlowForm flowId={flowId} /> : <CreateRecoveryFlowForm />}

            <Divider sx={{ my: 4 }} />
            <Typography variant="body2" sx={{ textAlign: "center" }}>
                Remember your credentials?{" "}
                <Link component={RouterLink} to="/login">
                    Sign in
                </Link>
            </Typography>
        </CardScaffold>
    );
};

export default RecoveryPage;
