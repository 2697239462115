import { Divider, Link, Stack, Typography } from "@mui/material";
import type { LoginResult } from "@mutations/login.js";
import BackupCredentialForm from "@pages/LoginPage/BackupCredentialForm.tsx";
import TotpCredentialForm from "@pages/LoginPage/TotpCredentialForm.tsx";
import WebAuthnCredentialForm from "@pages/LoginPage/WebAuthnCredentialForm.tsx";
import type { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";

type Props = {
    loginResult: LoginResult;
    onSuccess: () => void;
    onExpired: () => void;
};

const MfaCredentialForm = ({ onSuccess, onExpired, loginResult }: Props): ReactNode => {
    return (
        <>
            <Typography sx={{ mb: 3 }}>Please authenticate with a second factor.</Typography>

            <Stack spacing={4}>
                {loginResult.meta.webAuthnOptions && (
                    <WebAuthnCredentialForm
                        flowId={loginResult.data.id}
                        webAuthnOptions={loginResult.meta.webAuthnOptions}
                        onSuccess={onSuccess}
                        onExpired={onExpired}
                    />
                )}

                {loginResult.meta.hasTotpCredential && (
                    <TotpCredentialForm
                        flowId={loginResult.data.id}
                        onSuccess={onSuccess}
                        onExpired={onExpired}
                    />
                )}

                {loginResult.meta.hasBackupCredential && (
                    <BackupCredentialForm
                        flowId={loginResult.data.id}
                        onSuccess={onSuccess}
                        onExpired={onExpired}
                    />
                )}
            </Stack>

            <Divider sx={{ my: 4 }} />
            <Typography variant="body2" sx={{ textAlign: "center" }}>
                Lost your credentials?{" "}
                <Link component={RouterLink} to="/recovery">
                    Recover account
                </Link>
            </Typography>
        </>
    );
};

export default MfaCredentialForm;
