import { CardScaffold } from "@components/Scaffold/index.js";
import type { LoginResult } from "@mutations/login.js";
import { useSnackbar } from "notistack";
import { type ReactNode, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import MfaCredentialForm from "./MfaCredentialForm.js";
import PasswordCredentialForm from "./PasswordCredentialForm.js";

const allowedReturnOrigins = import.meta.env.VITE_APP_ALLOWED_RETURN_ORIGINS.split(",");

const LoginPage = (): ReactNode => {
    const [loginResult, setLoginResult] = useState<LoginResult | null>(null);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const handleSuccess = () => {
        const returnTo = searchParams.get("returnTo");

        if (!returnTo) {
            navigate("/");
            return;
        }

        const url = new URL(returnTo);

        if (allowedReturnOrigins.includes(url.origin)) {
            window.location.href = returnTo;
            return;
        }

        navigate("/");
    };

    return (
        <CardScaffold title="Login" showLogo>
            {loginResult ? (
                <MfaCredentialForm
                    loginResult={loginResult}
                    onSuccess={handleSuccess}
                    onExpired={() => {
                        setLoginResult(null);
                        enqueueSnackbar("The login flow has expired", { variant: "error" });
                    }}
                />
            ) : (
                <PasswordCredentialForm
                    onSuccess={(result) => {
                        if (!result) {
                            handleSuccess();
                            return;
                        }

                        setLoginResult(result);
                    }}
                />
            )}
        </CardScaffold>
    );
};

export default LoginPage;
