import { Button } from "@mui/material";
import { type LoginResult, useUpdateLoginFlowMutation } from "@mutations/login.js";
import { startAuthentication } from "@simplewebauthn/browser";
import { flowExpired, getFirstPointerErrorMessage } from "@utils/api.ts";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";

type Props = {
    flowId: string;
    webAuthnOptions: NonNullable<LoginResult["meta"]["webAuthnOptions"]>;
    onSuccess: () => void;
    onExpired: () => void;
};

const BackupCredentialForm = ({
    flowId,
    webAuthnOptions,
    onSuccess,
    onExpired,
}: Props): ReactNode => {
    const updateLoginFlowMutation = useUpdateLoginFlowMutation();
    const { enqueueSnackbar } = useSnackbar();

    const handleWebAuthn = () => {
        startAuthentication(webAuthnOptions)
            .then((response) => {
                updateLoginFlowMutation.mutate(
                    { flowId: flowId, webAuthnResponse: response },
                    {
                        onSuccess,
                        onError: (error) => {
                            if (flowExpired(error)) {
                                onExpired();
                                return;
                            }

                            const message = getFirstPointerErrorMessage(
                                error,
                                "data.attributes.webAuthnResponse",
                            );
                            enqueueSnackbar(message, { variant: "error" });
                        },
                    },
                );
            })
            .catch((error: Error) => {
                enqueueSnackbar(error.message, { variant: "error" });
            });
    };

    return (
        <Button variant="contained" onClick={handleWebAuthn} fullWidth>
            Use security key
        </Button>
    );
};

export default BackupCredentialForm;
