import { useSessionRefresh } from "@components/SessionRefreshProvider/SessionRefreshProvider.js";
import { zodResolver } from "@hookform/resolvers/zod";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyIcon from "@mui/icons-material/Key";
import RemoveIcon from "@mui/icons-material/Remove";
import {
    Button,
    Container,
    Divider,
    IconButton,
    InputAdornment,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import {
    type WebAuthnFlow,
    useCreateWebAuthnFlowMutation,
    useDeleteWebauthnMutation,
    useUpdateWebAuthnFlowMutation,
} from "@mutations/webauthn.js";
import { useWebAuthnCredentialsQuery } from "@queries/credentials.js";
import { startRegistration } from "@simplewebauthn/browser";
import type { PublicKeyCredentialCreationOptionsJSON } from "@simplewebauthn/types";
import { sessionRefreshRequired } from "@utils/api.ts";
import { RhfTextField } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import { z } from "zod";

const schema = z.object({
    displayName: z.string().trim().min(1),
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

const SecurityKeysPage = (): ReactNode => {
    const webAuthnCredentialsQuery = useWebAuthnCredentialsQuery();
    const createWebAuthnFlowMutation = useCreateWebAuthnFlowMutation();
    const updateWebAuthnFlowMutation = useUpdateWebAuthnFlowMutation();
    const deleteWebAuthnMutation = useDeleteWebauthnMutation();
    const refreshSession = useSessionRefresh();
    const { enqueueSnackbar } = useSnackbar();

    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
    });

    const completeFlow = (flow: WebAuthnFlow) => {
        startRegistration(flow.meta?.options as PublicKeyCredentialCreationOptionsJSON)
            .then((response) => {
                updateWebAuthnFlowMutation.mutate(
                    { flowId: flow.data.id, response },
                    {
                        onSuccess: () => {
                            enqueueSnackbar("Security key has been added", { variant: "success" });
                            form.reset({ displayName: "" });
                        },
                        onError: (error) => {
                            enqueueSnackbar(error.message, { variant: "error" });
                        },
                    },
                );
            })
            .catch((error: Error) => {
                enqueueSnackbar(error.message, { variant: "error" });
            });
    };

    const handleSubmit = (values: TransformedValues) => {
        createWebAuthnFlowMutation.mutate(values, {
            onSuccess: completeFlow,
            onError: (error) => {
                if (!sessionRefreshRequired(error)) {
                    enqueueSnackbar(error.message, { variant: "error" });
                    return;
                }

                refreshSession()
                    .then((refreshed) => {
                        if (refreshed) {
                            handleSubmit(values);
                        }
                    })
                    .catch(console.error);
            },
        });
    };

    const handleDelete = (id: string) => {
        deleteWebAuthnMutation.mutate(
            { id },
            {
                onSuccess: () => {
                    enqueueSnackbar("Security key has been removed", { variant: "success" });
                },
                onError: (error) => {
                    if (!sessionRefreshRequired(error)) {
                        return;
                    }

                    refreshSession()
                        .then((refreshed) => {
                            if (refreshed) {
                                handleDelete(id);
                            }
                        })
                        .catch(console.error);
                },
            },
        );
    };

    return (
        <Container maxWidth="sm">
            <Stack direction="row" spacing={2} alignItems="center">
                <IconButton component={RouterLink} to="/settings">
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h5">Security Keys</Typography>
            </Stack>

            <Divider sx={{ mt: 1, mb: 3 }} />

            <Paper variant="outlined" sx={{ p: { xs: 2, sm: 4 } }}>
                <Typography variant="body2" sx={{ mb: 4 }}>
                    Secure your account with any WebAuthn compatible security key. Please note that
                    this authentication scheme is only available on the web. When setting up
                    security keys, you must also configure an alternate second factor in order to
                    log into the mobile app.
                </Typography>

                <form noValidate onSubmit={form.handleSubmit(handleSubmit)}>
                    <RhfTextField
                        control={form.control}
                        name="displayName"
                        label="Display name"
                        required
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button type="submit">Add key</Button>
                                </InputAdornment>
                            ),
                        }}
                    />
                </form>
            </Paper>

            {webAuthnCredentialsQuery.isPending && <LinearProgress />}

            {webAuthnCredentialsQuery.data && webAuthnCredentialsQuery.data.length > 0 && (
                <Paper variant="outlined" sx={{ mt: 3 }}>
                    <List>
                        {webAuthnCredentialsQuery.data.map((key) => (
                            <ListItem
                                key={key.id}
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        onClick={() => {
                                            handleDelete(key.id);
                                        }}
                                    >
                                        <RemoveIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemIcon>
                                    <KeyIcon />
                                </ListItemIcon>
                                <ListItemText>{key.displayName}</ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            )}
        </Container>
    );
};

export default SecurityKeysPage;
