import ErrorAlert from "@components/ErrorAlert";
import { RhfPasswordField } from "@components/Rhf/index.js";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useSessionRefreshMutation } from "@mutations/session.js";
import type { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
    password: z.string().trim().min(1),
});

type FormValues = z.infer<typeof schema>;

type Props = {
    open: boolean;
    onSuccess: () => void;
    onCancel: () => void;
};

const SessionRefreshDialog = ({ open, onSuccess, onCancel }: Props): ReactNode => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const sessionRefreshMutation = useSessionRefreshMutation();

    const form = useForm<FormValues>({
        resolver: zodResolver(schema),
    });

    const handleSubmit = (values: FormValues) => {
        sessionRefreshMutation.mutate(values, { onSuccess });
    };

    return (
        <Dialog
            open={open}
            onClose={sessionRefreshMutation.isPending ? undefined : onCancel}
            maxWidth="xs"
            fullWidth
            fullScreen={fullScreen}
        >
            <Box
                component="form"
                noValidate
                onSubmit={form.handleSubmit(handleSubmit)}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                }}
            >
                <DialogTitle>Verification required</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ mb: 3 }}>
                        In order to perform this action, please verify that it's you by entering
                        your current password.
                    </DialogContentText>

                    {sessionRefreshMutation.isError && (
                        <ErrorAlert error={sessionRefreshMutation.error} sx={{ mb: 4 }} />
                    )}

                    <RhfPasswordField
                        control={form.control}
                        name="password"
                        fullWidth
                        autoComplete="current-password"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} disabled={sessionRefreshMutation.isPending}>
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        loading={sessionRefreshMutation.isPending}
                    >
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default SessionRefreshDialog;
