import { useSessionRefresh } from "@components/SessionRefreshProvider/SessionRefreshProvider.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import {
    Card,
    CardActionArea,
    CardContent,
    Container,
    Divider,
    IconButton,
    LinearProgress,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useGenerateBackupMutation } from "@mutations/backup.js";
import { useBackupCredentialQuery } from "@queries/credentials.js";
import { sessionRefreshRequired } from "@utils/api.js";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";

const BackupCodesPage = (): ReactNode => {
    const backupCodesQuery = useBackupCredentialQuery();
    const refreshSession = useSessionRefresh();
    const generateBackupCodesMutations = useGenerateBackupMutation();
    const { enqueueSnackbar } = useSnackbar();

    const handleGenerate = () => {
        generateBackupCodesMutations.mutate(undefined, {
            onSuccess: () => {
                enqueueSnackbar("New backup codes have been generated", { variant: "success" });
            },
            onError: (error) => {
                if (!sessionRefreshRequired(error)) {
                    return;
                }

                refreshSession()
                    .then((refreshed) => {
                        if (refreshed) {
                            handleGenerate();
                        }
                    })
                    .catch(console.error);
            },
        });
    };

    const copyBackupCodes = () => {
        if (!backupCodesQuery.data) {
            return;
        }

        const backupCodes = backupCodesQuery.data.codes
            .filter((code) => !code.usedAt)
            .map((code) => code.code)
            .join("\n");

        navigator.clipboard
            .writeText(backupCodes)
            .then(() => {
                enqueueSnackbar("Backup codes copied to clipboard", { variant: "success" });
            })
            .catch(() => {
                enqueueSnackbar("Failed to copy backup codes to clipboard", { variant: "error" });
            });
    };

    return (
        <Container maxWidth="sm">
            <Stack direction="row" spacing={2} alignItems="center">
                <IconButton component={RouterLink} to="/settings">
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h5">Backup codes</Typography>
            </Stack>

            <Divider sx={{ mt: 1, mb: 3 }} />

            <Paper variant="outlined" sx={{ p: { xs: 2, sm: 4 } }}>
                <Typography variant="body2" sx={{ mb: 4 }}>
                    If you ever lose access to your second factor, you can use a backup code in
                    order to sign in. Make sure to save these in a secure location.
                </Typography>

                {backupCodesQuery.isPending ? (
                    <LinearProgress />
                ) : (
                    <>
                        {backupCodesQuery.data && (
                            <Card variant="outlined" sx={{ mb: 4 }}>
                                <CardActionArea onClick={copyBackupCodes}>
                                    <CardContent>
                                        <Grid container>
                                            {backupCodesQuery.data.codes.map((code) => (
                                                <Grid xs={4} sm={3} key={code.code}>
                                                    <Typography
                                                        fontFamily="monospace"
                                                        variant="body2"
                                                    >
                                                        {code.usedAt ? "used" : code.code}
                                                    </Typography>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        )}

                        <LoadingButton
                            variant="outlined"
                            onClick={handleGenerate}
                            loading={generateBackupCodesMutations.isPending}
                        >
                            Generate
                        </LoadingButton>
                    </>
                )}
            </Paper>
        </Container>
    );
};

export default BackupCodesPage;
