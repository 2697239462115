import { Alert, type SxProps } from "@mui/material";
import { JsonApiError } from "jsonapi-zod-query";
import type { ReactNode } from "react";

type Props = {
    error: Error;
    sx: SxProps;
};

const ErrorAlert = ({ error, sx }: Props): ReactNode => {
    const errorMessage =
        error instanceof JsonApiError
            ? error.errors[0].detail ?? error.errors[0].title
            : error.message;

    return (
        <Alert severity="error" sx={sx}>
            {errorMessage}
        </Alert>
    );
};

export default ErrorAlert;
