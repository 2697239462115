import { Button, Card, CardActions, CardContent, CardHeader, Container } from "@mui/material";
import { useDeleteSessionMutation } from "@mutations/session.js";
import type { ReactNode } from "react";

const LogoutPage = (): ReactNode => {
    const deleteSessionMutation = useDeleteSessionMutation();

    return (
        <Container maxWidth="sm">
            <Card variant="outlined">
                <CardHeader title="Logout" />
                <CardContent>Please confirm that you want to log out of your account.</CardContent>
                <CardActions sx={{ mt: "auto" }}>
                    <Button
                        onClick={() => {
                            deleteSessionMutation.mutate();
                        }}
                    >
                        Log me out
                    </Button>
                </CardActions>
            </Card>
        </Container>
    );
};

export default LogoutPage;
