import { Stack } from "@mui/material";
import type { ReactNode } from "react";
import ProfileCard from "./ProfileCard.tsx";
import SecurityCard from "./SecurityCard.tsx";
import SessionsCard from "./SessionsCard.tsx";

const SettingsPage = (): ReactNode => {
    return (
        <Stack spacing={3}>
            <ProfileCard />
            <SecurityCard />
            <SessionsCard />
        </Stack>
    );
};

export default SettingsPage;
