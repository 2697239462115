import VisibilityIcon from "@mui/icons-material/Visibility.js";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff.js";
import { IconButton, InputAdornment } from "@mui/material";
import { RhfTextField } from "mui-rhf-integration";
import type { RhfTextFieldProps } from "mui-rhf-integration";
import { type ReactNode, useState } from "react";
import type { FieldPath, FieldValues } from "react-hook-form";

type Props<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<RhfTextFieldProps<TFieldValues, TName>, "type">;

const RhfPasswordField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
    props: Props<TFieldValues, TName>,
): ReactNode => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <RhfTextField
            {...props}
            type={showPassword ? "text" : "password"}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                                setShowPassword(!showPassword);
                            }}
                            onMouseDown={(event) => {
                                event.preventDefault();
                            }}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default RhfPasswordField;
