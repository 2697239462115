import { useSession } from "@components/Scaffold/index.js";
import UserAvatar from "@components/UserAvatar/UserAvatar.js";
import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useDeletePictureMutation } from "@mutations/profile.js";
import { fileOpen } from "browser-fs-access";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";

type Props = {
    onClose: () => void;
    onImageSelect: (imageSource: string) => void;
};

const CurrentPfp = ({ onClose, onImageSelect }: Props): ReactNode => {
    const session = useSession();
    const { enqueueSnackbar } = useSnackbar();
    const deletePfpMutation = useDeletePictureMutation();

    const selectImage = async () => {
        const image = await fileOpen({ mimeTypes: ["image/*"] });
        const reader = new FileReader();

        reader.addEventListener("load", () => {
            onImageSelect(reader.result as string);
        });

        reader.readAsDataURL(image);
    };

    const handleImageSelect = () => {
        selectImage().catch(() => {
            enqueueSnackbar("Failed to load image", { variant: "error" });
        });
    };

    const handleRemove = () => {
        deletePfpMutation.mutate(undefined, {
            onError: (error) => {
                enqueueSnackbar(error.message, { variant: "error" });
            },
            onSuccess: () => {
                enqueueSnackbar("Profile picture has been removed", { variant: "success" });
                onClose();
            },
        });
    };

    return (
        <>
            <DialogTitle>Profile Picture</DialogTitle>
            <DialogContent dividers>
                <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                    <UserAvatar identity={session.identity} size={250} />
                </Box>

                <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                    <Button
                        disabled={deletePfpMutation.isPending}
                        variant="outlined"
                        onClick={handleImageSelect}
                        sx={{ flexGrow: 1 }}
                    >
                        Select picture
                    </Button>

                    {session.identity.picture && (
                        <LoadingButton
                            variant="outlined"
                            onClick={handleRemove}
                            loading={deletePfpMutation.isPending}
                            sx={{ flexGrow: 1 }}
                        >
                            Remove picture
                        </LoadingButton>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button disabled={deletePfpMutation.isPending} onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </>
    );
};

export default CurrentPfp;
