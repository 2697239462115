import useApiFetch from "@hooks/useApiFetch.js";
import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { apiUrl } from "@utils/api.js";
import { handleJsonApiError } from "jsonapi-zod-query";
import { z } from "zod";

type RegistrationValues = {
    nickname: string;
    emailAddress: string;
    password: string;
};

const registrationResultSchema = z.object({
    meta: z.object({
        verificationFlowId: z.string(),
    }),
});

type RegistrationResultMeta = z.output<typeof registrationResultSchema>["meta"];

export const useRegistrationMutation = (): UseMutationResult<
    RegistrationResultMeta,
    Error,
    RegistrationValues
> => {
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(apiUrl("/identity/self-service/identity").toString(), {
                method: "POST",
                body: JSON.stringify({
                    data: {
                        type: "identity",
                        attributes: values,
                    },
                }),
                headers: {
                    "Content-Type": "application/vnd.api+json",
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);

            const raw = await response.json();
            return registrationResultSchema.parse(raw).meta;
        },
    });
};
