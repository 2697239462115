import useApiFetch from "@hooks/useApiFetch.js";
import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { apiUrl } from "@utils/api.js";
import { createDataSelector, createResourceSelector, handleJsonApiError } from "jsonapi-zod-query";

type CreateVerificationFlowValues = {
    emailAddress: string;
};

const verificationFlowSelector = createDataSelector(
    createResourceSelector({
        type: "verification_flow",
    }),
);

export type VerificationFlow = ReturnType<typeof verificationFlowSelector>;

export const useCreateVerificationFlowMutation = (): UseMutationResult<
    VerificationFlow,
    Error,
    CreateVerificationFlowValues
> => {
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl("/identity/self-service/flows/verification").toString(),
                {
                    method: "POST",
                    body: JSON.stringify({
                        data: {
                            type: "verification_flow",
                            attributes: values,
                        },
                    }),
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);

            return verificationFlowSelector(await response.json());
        },
    });
};

type UpdateVerificationFlowValues = {
    flowId: string;
    code: string;
};

export const useUpdateVerificationFlowMutation = (): UseMutationResult<
    void,
    Error,
    UpdateVerificationFlowValues
> => {
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const { flowId, ...rest } = values;

            const response = await fetch(
                apiUrl(`/identity/self-service/flows/verification/${flowId}`).toString(),
                {
                    method: "PATCH",
                    body: JSON.stringify({
                        data: {
                            id: flowId,
                            type: "verification_flow",
                            attributes: rest,
                        },
                    }),
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);
        },
    });
};
