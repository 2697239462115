import useApiFetch from "@hooks/useApiFetch.js";
import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { apiUrl } from "@utils/api.js";
import { createDataSelector, createResourceSelector, handleJsonApiError } from "jsonapi-zod-query";

type CreateRecoveryFlowValues = {
    emailAddress: string;
};

const recoveryFlowSelector = createDataSelector(
    createResourceSelector({
        type: "recovery_flow",
    }),
);

export type RecoveryFlow = ReturnType<typeof recoveryFlowSelector>;

export const useCreateRecoveryFlowMutation = (): UseMutationResult<
    RecoveryFlow,
    Error,
    CreateRecoveryFlowValues
> => {
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl("/identity/self-service/flows/recovery").toString(),
                {
                    method: "POST",
                    body: JSON.stringify({
                        data: {
                            type: "recovery_flow",
                            attributes: values,
                        },
                    }),
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);

            return recoveryFlowSelector(await response.json());
        },
    });
};

type UpdateRecoveryFlowValues = {
    flowId: string;
    code: string;
};

export const useUpdateRecoveryFlowMutation = (): UseMutationResult<
    void,
    Error,
    UpdateRecoveryFlowValues
> => {
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const { flowId, ...rest } = values;

            const response = await fetch(
                apiUrl(`/identity/self-service/flows/recovery/${flowId}`).toString(),
                {
                    method: "PATCH",
                    body: JSON.stringify({
                        data: {
                            id: flowId,
                            type: "recovery_flow",
                            attributes: rest,
                        },
                    }),
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);
        },
    });
};
