import useApiFetch from "@hooks/useApiFetch.js";
import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiUrl } from "@utils/api.js";
import { createResourceSelector, handleJsonApiError } from "jsonapi-zod-query";
import { z } from "zod";

const totpFlowSelector = createResourceSelector({
    type: "totp_flow",
    attributesSchema: z.object({
        secret: z.string(),
    }),
});

export type TotpFlow = ReturnType<typeof totpFlowSelector>;

export const useCreateTotpFlowMutation = (): UseMutationResult<TotpFlow, Error, void> => {
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(apiUrl("/identity/self-service/flows/totp").toString(), {
                method: "POST",
                body: JSON.stringify({
                    data: {
                        type: "totp_flow",
                        attributes: values,
                    },
                }),
                headers: {
                    "Content-Type": "application/vnd.api+json",
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);

            return totpFlowSelector(await response.json());
        },
    });
};

export type UpdateTotpFlowValues = {
    flowId: string;
    passcode: string;
};

export const useUpdateTotpFlowMutation = (): UseMutationResult<
    void,
    Error,
    UpdateTotpFlowValues
> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const { flowId, ...rest } = values;

            const response = await fetch(
                apiUrl(`/identity/self-service/flows/totp/${flowId}`).toString(),
                {
                    method: "PATCH",
                    body: JSON.stringify({
                        data: {
                            id: flowId,
                            type: "totp_flow",
                            attributes: rest,
                        },
                    }),
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["credentials", "status"] });
        },
    });
};

export const useDeleteTotpMutation = (): UseMutationResult<void, Error, void> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async () => {
            const response = await fetch(
                apiUrl("/identity/self-service/identity/credentials/totp").toString(),
                {
                    method: "DELETE",
                    headers: {
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["credentials", "status"] });
        },
    });
};
