import ErrorAlert from "@components/ErrorAlert/index.ts";
import { RhfPasswordField } from "@components/Rhf/index.js";
import { useSessionRefresh } from "@components/SessionRefreshProvider/SessionRefreshProvider.js";
import { zodResolver } from "@hookform/resolvers/zod";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import { Button, Container, Divider, IconButton, Paper, Stack, Typography } from "@mui/material";
import { useUpdatePasswordMutation } from "@mutations/credential.js";
import { sessionRefreshRequired } from "@utils/api.ts";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { z } from "zod";

const schema = z.object({
    password: z.string().min(8),
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

const UpdatePasswordPage = (): JSX.Element => {
    const updatePasswordMutation = useUpdatePasswordMutation();
    const refreshSession = useSessionRefresh();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
    });

    const handleSubmit = (values: TransformedValues) => {
        updatePasswordMutation.mutate(values, {
            onSuccess: () => {
                enqueueSnackbar("Your password has been updated", { variant: "success" });
                navigate("/settings");
            },
            onError: (error) => {
                if (!sessionRefreshRequired(error)) {
                    return;
                }

                refreshSession()
                    .then((refreshed) => {
                        if (refreshed) {
                            handleSubmit(values);
                        }
                    })
                    .catch(console.error);
            },
        });
    };

    return (
        <Container maxWidth="sm">
            <Stack direction="row" spacing={2} alignItems="center">
                <IconButton component={RouterLink} to="/settings">
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h5">Password</Typography>
            </Stack>

            <Divider sx={{ mt: 1, mb: 3 }} />

            <Paper variant="outlined" sx={{ p: { xs: 2, sm: 4 } }}>
                <Typography variant="body2" sx={{ mb: 4 }}>
                    Update your password used to log into Furvester.
                </Typography>

                <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
                    {updatePasswordMutation.isError &&
                        !sessionRefreshRequired(updatePasswordMutation.error) && (
                            <ErrorAlert error={updatePasswordMutation.error} sx={{ mb: 4 }} />
                        )}

                    <RhfPasswordField
                        control={form.control}
                        label="New password"
                        name="password"
                        autoComplete="new-password"
                        required
                        fullWidth
                    />

                    <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 4 }}>
                        <Button component={RouterLink} to="/settings">
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            sx={{ mt: 4 }}
                            loading={updatePasswordMutation.isPending}
                        >
                            Save
                        </LoadingButton>
                    </Stack>
                </form>
            </Paper>
        </Container>
    );
};

export default UpdatePasswordPage;
