import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import WebIcon from "@mui/icons-material/Web";
import { LoadingButton } from "@mui/lab";
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    Typography,
} from "@mui/material";
import { useDeleteOtherSessionsMutation } from "@mutations/session.js";
import { useSessionsQuery } from "@queries/session.js";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { WrappingListItemText } from "./WrappingListItemText.js";

const SessionsCard = (): ReactNode => {
    const sessionsQuery = useSessionsQuery();
    const deleteOtherSessionsMutation = useDeleteOtherSessionsMutation();
    const { enqueueSnackbar } = useSnackbar();

    if (!sessionsQuery.data) {
        return (
            <Card>
                <CardHeader title="Sessions" />
                <CardContent sx={{ p: 0 }}>
                    <LinearProgress />
                </CardContent>
            </Card>
        );
    }

    const browserSessions = sessionsQuery.data.filter(
        (session) => session.clientType === "browser",
    );
    const nativeSessions = sessionsQuery.data.filter((session) => session.clientType === "native");

    return (
        <Card>
            <CardHeader title="Sessions" />
            <CardContent sx={{ p: 0 }}>
                <List disablePadding>
                    <ListItem>
                        <ListItemIcon>
                            <WebIcon />
                        </ListItemIcon>
                        <WrappingListItemText
                            primary="Browser"
                            secondary={
                                <Typography variant="body2">
                                    {browserSessions.length} session
                                    {browserSessions.length !== 1 && "s"}
                                </Typography>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <PhoneAndroidIcon />
                        </ListItemIcon>
                        <WrappingListItemText
                            primary="Mobile App"
                            secondary={
                                <Typography variant="body2">
                                    {nativeSessions.length} session
                                    {nativeSessions.length !== 1 && "s"}
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>
            </CardContent>
            <CardActions>
                <LoadingButton
                    loading={deleteOtherSessionsMutation.isPending}
                    onClick={() => {
                        deleteOtherSessionsMutation.mutate(undefined, {
                            onSuccess: () => {
                                enqueueSnackbar("Other sessions have been removed", {
                                    variant: "success",
                                });
                            },
                            onError: (error) => {
                                enqueueSnackbar(error.message, { variant: "error" });
                            },
                        });
                    }}
                >
                    <span>Remove other sessions</span>
                </LoadingButton>
            </CardActions>
        </Card>
    );
};

export default SessionsCard;
