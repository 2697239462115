import logoShort from "@assets/logo-short.svg";
import PfpDialog from "@components/PfpDialog/index.js";
import { SessionContext } from "@components/Scaffold/SessionContext.js";
import SessionRefreshProvider from "@components/SessionRefreshProvider/index.js";
import UserAvatar from "@components/UserAvatar/UserAvatar.js";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import {
    AppBar,
    Badge,
    Box,
    Container,
    Divider,
    IconButton,
    LinearProgress,
    ListItemIcon,
    Menu,
    MenuItem,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import { useDeleteSessionMutation } from "@mutations/session.js";
import { type Session, useCurrentSessionQuery } from "@queries/session.js";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import type { ReactNode } from "react";
import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";

type Props = {
    children?: ReactNode;
};

const SessionScaffold = ({ children }: Props): ReactNode => {
    const sessionQuery = useCurrentSessionQuery();
    const navigate = useNavigate();
    const menuState = usePopupState({ variant: "popover", popupId: "userMenu" });
    const [pfpDialogOpen, setPfpDialogOpen] = useState(false);
    const deleteSessionMutation = useDeleteSessionMutation();

    useEffect(() => {
        if (sessionQuery.data === null) {
            navigate("/login");
        }
    }, [sessionQuery.data, navigate]);

    if (sessionQuery.isError) {
        console.error(sessionQuery.error);
        throw new Error("Failed to load session");
    }

    if (!sessionQuery.data) {
        return <LinearProgress />;
    }

    const session = sessionQuery.data;

    return (
        <SessionContext.Provider value={session}>
            <SessionRefreshProvider>
                <AppBar position="static">
                    <Toolbar>
                        <Box
                            component="img"
                            src={logoShort}
                            alt="Furvester"
                            sx={{ height: 40, mr: 2 }}
                        />

                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Identity
                        </Typography>
                        <IconButton edge="end" {...bindTrigger(menuState)}>
                            <UserAvatar identity={session.identity} />
                        </IconButton>
                        <Menu {...bindMenu(menuState)}>
                            <Box
                                sx={{
                                    width: 390,
                                    p: 2,
                                }}
                            >
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                        badgeContent={
                                            <Box
                                                sx={{
                                                    position: "absolute",
                                                    right: -6,
                                                    bottom: -6,
                                                    backgroundColor: "grey.900",
                                                    borderRadius: "50%",
                                                }}
                                            >
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        menuState.close();
                                                        setPfpDialogOpen(true);
                                                    }}
                                                >
                                                    <PhotoCameraIcon fontSize="small" />
                                                </IconButton>
                                            </Box>
                                        }
                                    >
                                        <UserAvatar identity={session.identity} size={64} />
                                    </Badge>
                                    <div>
                                        <Typography>{session.identity.nickname}</Typography>
                                        <Typography variant="body2">
                                            {session.identity.emailAddress}
                                        </Typography>
                                    </div>
                                </Stack>
                            </Box>

                            <Divider sx={{ mb: 1 }} />

                            <MenuItem
                                onClick={() => {
                                    menuState.close();
                                    navigate("/");
                                }}
                            >
                                <ListItemIcon>
                                    <DashboardIcon />
                                </ListItemIcon>
                                Dashboard
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    menuState.close();
                                    navigate("/settings");
                                }}
                            >
                                <ListItemIcon>
                                    <ManageAccountsIcon />
                                </ListItemIcon>
                                Settings
                            </MenuItem>
                            <Divider />
                            <MenuItem
                                onClick={() => {
                                    menuState.close();
                                    deleteSessionMutation.mutate();
                                }}
                            >
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
                <Container component="main" sx={{ py: 4 }}>
                    {children}
                    <Outlet />
                </Container>

                <PfpDialog
                    open={pfpDialogOpen}
                    onClose={() => {
                        setPfpDialogOpen(false);
                    }}
                />
            </SessionRefreshProvider>
        </SessionContext.Provider>
    );
};

export const useSession = (): Session => {
    const context = useContext(SessionContext);

    if (!context) {
        throw new Error("Context used outside provider");
    }

    return context;
};

export default SessionScaffold;
