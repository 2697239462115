import { useSession } from "@components/Scaffold/index.js";
import UserAvatar from "@components/UserAvatar/UserAvatar.js";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Stack,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import type { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";

const DashboardPage = (): ReactNode => {
    const session = useSession();

    return (
        <>
            <Stack spacing={4} alignItems="center" sx={{ mb: 4 }}>
                <UserAvatar identity={session.identity} size={96} />
                <Typography variant="h5">Welcome, {session.identity.nickname}</Typography>
            </Stack>

            <Grid container spacing={3}>
                <Grid xs={12} md={6}>
                    <Card
                        variant="outlined"
                        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
                    >
                        <CardHeader title="Edit your account settings" />
                        <CardContent>
                            Change your nickname, profile picture, email address or login
                            credentials.
                        </CardContent>
                        <CardActions sx={{ mt: "auto" }}>
                            <Button component={RouterLink} to="/settings">
                                Edit settings
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid xs={12} md={6}>
                    <Card
                        variant="outlined"
                        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
                    >
                        <CardHeader title="Convention registration" />
                        <CardContent>
                            Register for Furvester or manage your existing registration.
                        </CardContent>
                        <CardActions sx={{ mt: "auto" }}>
                            <Button
                                component="a"
                                href={import.meta.env.VITE_APP_REGISTRATION_DASHBOARD_URL}
                            >
                                Open registration
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default DashboardPage;
