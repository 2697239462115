import ErrorAlert from "@components/ErrorAlert/index.ts";
import { RhfPasswordField } from "@components/Rhf/index.js";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Divider, Link, Stack, Typography } from "@mui/material";
import { useCreateLoginFlowMutation } from "@mutations/login.js";
import type { LoginResult } from "@mutations/login.js";
import { RhfTextField } from "mui-rhf-integration";
import type { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import { z } from "zod";

const schema = z.object({
    emailAddress: z.string().email(),
    password: z.string().min(1),
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

type Props = {
    onSuccess: (result: LoginResult | null) => void;
};

const PasswordCredentialForm = ({ onSuccess }: Props): ReactNode => {
    const createLoginFlowMutation = useCreateLoginFlowMutation();

    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
    });

    const handleSubmit = (values: TransformedValues) => {
        createLoginFlowMutation.mutate(values, { onSuccess });
    };

    return (
        <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
            {createLoginFlowMutation.isError && (
                <ErrorAlert error={createLoginFlowMutation.error} sx={{ mb: 4 }} />
            )}

            <Stack spacing={2}>
                <RhfTextField
                    control={form.control}
                    label="Email address"
                    name="emailAddress"
                    type="email"
                    required
                    autoComplete="username"
                    disabled={createLoginFlowMutation.isPending}
                />
                <RhfPasswordField
                    control={form.control}
                    label="Password"
                    name="password"
                    required
                    autoComplete="current-password"
                    disabled={createLoginFlowMutation.isPending}
                />
                <Link component={RouterLink} to="/recovery">
                    Forgot Password?
                </Link>
            </Stack>

            <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 4 }}
                loading={createLoginFlowMutation.isPending}
            >
                Sign in
            </LoadingButton>

            <Divider sx={{ my: 4 }} />
            <Typography variant="body2" sx={{ textAlign: "center" }}>
                Don't have an account?{" "}
                <Link component={RouterLink} to="/registration">
                    Sign up
                </Link>
            </Typography>
        </form>
    );
};

export default PasswordCredentialForm;
