import ErrorAlert from "@components/ErrorAlert/index.ts";
import { useSession } from "@components/Scaffold/index.js";
import { useSessionRefresh } from "@components/SessionRefreshProvider/SessionRefreshProvider.js";
import { zodResolver } from "@hookform/resolvers/zod";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import { Button, Container, Divider, IconButton, Paper, Stack, Typography } from "@mui/material";
import { useUpdateEmailAddressMutation } from "@mutations/credential.js";
import { sessionRefreshRequired } from "@utils/api.ts";
import { RhfTextField } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { z } from "zod";

const schema = z.object({
    emailAddress: z.string().email(),
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

const UpdateEmailAddressPage = (): ReactNode => {
    const session = useSession();
    const updateEmailAddressMutation = useUpdateEmailAddressMutation();
    const refreshSession = useSessionRefresh();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
        defaultValues: {
            emailAddress: session.identity.emailAddress,
        },
    });

    const handleSubmit = (values: TransformedValues) => {
        updateEmailAddressMutation.mutate(values, {
            onSuccess: (result) => {
                if (!result) {
                    enqueueSnackbar("Your email address has not changed", { variant: "info" });
                    navigate("/settings");
                    return;
                }

                enqueueSnackbar("An email with a verification code has been send to your address", {
                    variant: "info",
                });
                navigate(`/verification?flowId=${result.verificationFlowId}`);
            },
            onError: (error) => {
                if (!sessionRefreshRequired(error)) {
                    return;
                }

                refreshSession()
                    .then((refreshed) => {
                        if (refreshed) {
                            handleSubmit(values);
                        }
                    })
                    .catch(console.error);
            },
        });
    };

    return (
        <Container maxWidth="sm">
            <Stack direction="row" spacing={2} alignItems="center">
                <IconButton component={RouterLink} to="/settings">
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h5">Email address</Typography>
            </Stack>

            <Divider sx={{ mt: 1, mb: 3 }} />

            <Paper variant="outlined" sx={{ p: { xs: 2, sm: 4 } }}>
                <Typography variant="body2" sx={{ mb: 4 }}>
                    Your email address is used for your login as well as sending you information
                    about your registration. Updating it requires you to verify your new email
                    address.
                </Typography>

                <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
                    {updateEmailAddressMutation.isError &&
                        !sessionRefreshRequired(updateEmailAddressMutation.error) && (
                            <ErrorAlert error={updateEmailAddressMutation.error} sx={{ mb: 4 }} />
                        )}

                    <RhfTextField
                        control={form.control}
                        label="Email address"
                        name="emailAddress"
                        type="email"
                        autoComplete="username"
                        required
                        disabled={updateEmailAddressMutation.isPending}
                        fullWidth
                    />

                    <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 4 }}>
                        <Button component={RouterLink} to="/settings">
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            sx={{ mt: 4 }}
                            loading={updateEmailAddressMutation.isPending}
                        >
                            Save
                        </LoadingButton>
                    </Stack>
                </form>
            </Paper>
        </Container>
    );
};

export default UpdateEmailAddressPage;
