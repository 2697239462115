import type { ReactNode } from "react";
import { createContext, useCallback, useContext, useState } from "react";
import SessionRefreshDialog from "./SessionRefreshDialog.js";

type SessionRefresh = () => Promise<boolean>;

const SessionRefreshContext = createContext<SessionRefresh | null>(null);

type ResolvePromise = (refreshed: boolean) => void;

type Props = {
    children: ReactNode;
};

const SessionRefreshProvider = ({ children }: Props): ReactNode => {
    const [resolvePromise, setResolvePromise] = useState<ResolvePromise | null>(null);

    const value = useCallback(async () => {
        return new Promise<boolean>((resolve) => {
            setResolvePromise(() => resolve);
        });
    }, []);

    return (
        <SessionRefreshContext.Provider value={value}>
            {children}

            <SessionRefreshDialog
                open={resolvePromise !== null}
                onSuccess={() => {
                    resolvePromise?.(true);
                    setResolvePromise(null);
                }}
                onCancel={() => {
                    resolvePromise?.(false);
                    setResolvePromise(null);
                }}
            />
        </SessionRefreshContext.Provider>
    );
};

export const useSessionRefresh = (): SessionRefresh => {
    const context = useContext(SessionRefreshContext);

    if (!context) {
        throw new Error("Context used outside provider");
    }

    return context;
};

export default SessionRefreshProvider;
